import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

import Box from '@mui/material/Box';

import MainIcons from '../MainIcons';
import MainBanner from '../MainBanner';
import ProductsListPage from './ProductsListPage';

import { VisibleIcons } from './../VisibleIcons';

interface HomePageProps {
  router: ToolpadRouter;
}

export default function HomePage({ router }: HomePageProps) {

  return (
    <>
      <MainBanner />
      <Box sx={{ height: 5 }} />
      <MainIcons visibleIcons={VisibleIcons.CATALOG | VisibleIcons.SEARCH} router={router} />
      <Box sx={{ height: 5 }} />
      <ProductsListPage router={router} uuid={null} />
    </>
  );
}

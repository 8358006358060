import Token from './Token';

interface Requisite {
    bin?: string;
    uuid?: string;
    type?: string;
    company?: string;
    address?: string;
    balance?: number;
}

interface User {
    id: string;
    type: string;
    tel: string;
    fullName: string;
    category: string;
    requisites: Requisite[];
}

interface Cart {
    client: string;
    user: User;
    tel: string;
    pay: string;
    comment: string;
    delivery: string;
    deliveryAddress: string;
    fullName: string;
    items: CartItem[];
    payNumber?: string;
}

interface CartItem {
    name: string;
    product: string;
    count: number;
    price: number;
}

interface DocumentProps {
    cart: Cart;
}

async function Document({ cart }: DocumentProps): Promise<boolean> {
    try {
        cart.payNumber = "";

        const headers = new Headers({
            'Content-Type': 'application/json',
        });

        const token = (new Token()).get();
        if (token) {
            headers.append('Token', token);
        }

        const response = await fetch('/api/cart/save', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(cart),
        });

        return response.ok;
    } catch (error) {
        console.error('Error fetching file:', error);
        return false;
    }
}

export default Document;

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export default function NotFoundPage() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  height: '100vh', textAlign: 'center' }}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 12 }}>
          <ReportProblemIcon sx={{width: 200, height: 200}} />
        </Grid>
        <Grid size={{ xs: 12, md: 12 }}>
          <Box component="section" sx={{ p: 2 }}>
             СТРАНИЦА НЕ НАЙДЕНА
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { IMaskInput } from 'react-imask';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';
import Token from './../Token';
import Format from './../Format';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EventBus from './../EventBus';
import TextField from '@mui/material/TextField';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import OutlinedInput from '@mui/material/OutlinedInput';

import Document from './../Document';
import HalykBank from "../HalykBank";

interface Requisite {
  bin?: string;
  uuid?: string;
  type?: string;
  company?: string;
  address?: string;
  balance?: number;
}

interface User {
  id: string;
  type: string;
  tel: string;
  payNull: boolean;
  fullName: string;
  category: string;
  requisites: Requisite[];
}

interface Cart {
  client: string;
  user: User;
  tel: string;
  pay: string;
  payNumber: string;
  comment: string;
  delivery: string;
  deliveryAddress: string;
  fullName: string;
  email: string;
  amount: number;
  items: CartItem[];
}

interface CartItem {
  name: string;
  product: string;
  count: number;
  price: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="+7 (000) 000-00-00"
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        value={props.value}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        prefix=""
      />
    );
  },
);

interface PaymentData {
  kaspi: boolean;
  halyk: boolean;
}

interface CartProps {
  router: ToolpadRouter;
}

function CartPage({ router }: CartProps) {
  const [count, setCount] = useState(0);
  const [uuid, setUUID] = useState("");
  const [message, setMessage] = useState("");
  const [conditions, setConditions] = useState("");
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConditions, setOpenDialogConditions] = useState(false);
  const [openCountDialog, setOpenCountDialog] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [cart, setCart] = useState<Cart>();
  const [openMessage, setOpenMessage] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const [payment, setPayment] = useState<PaymentData>({kaspi: false, halyk: false,});

  const handlePayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCartValue("pay", event.target.value);
  };

  const handleDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCartValue("delivery", event.target.value);
  };

  const handleDeliveryRequisiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const requisite = cart?.user.requisites.find((requisite) => requisite.uuid === event.target.value);
    if (requisite) {
      setCartValue("deliveryAddress", requisite.address || "");
    }
  };

  const handleChangeDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCartValue("deliveryAddress", event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (cart) {
      setCart({
        ...cart,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const setCartValue = (field: string, value: any) => {
    if (cart) {
      setCart({
        ...cart,
        [field]: value,
      });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleUUID = (value: string) => {
    setUUID(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (openDialogConditions) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialogConditions]);

  const handleClickOpenConditions = (scrollType: DialogProps['scroll']) => async () => {
    try {
      const response = await fetch('/doc/PrivacyPolicy.txt');
      const data = await response.text();

      setConditions(data);
    } catch (error) {
      console.error('Error fetching file:', error);
    }

    setOpenDialogConditions(true);
    setScroll(scrollType);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClickOpenCountDialog = () => {
    setOpenCountDialog(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseConditions = () => {
    setOpenDialogConditions(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseCountDialog = () => {
    setOpenCountDialog(false);
  };

  function checkData() {
    if (cart?.user !== null) {
      if (cart?.user.type === 'LEGAL') {
        if (cart?.email === "") {
          setMessage("Заполните email");

          return false;
        }
      }
    }

    if (cart?.tel === "") {
      setMessage("Заполните номер телефона");

      return false;
    }

    if (cart?.fullName === "") {
      setMessage("Заполните Ф. И. О.");

      return false;
    }

    if (cart?.user !== null) {
      if (cart?.user.type === 'UNDEFINED' || cart?.user.type === 'RETAIL') {
        if (cart?.delivery === 'UNDEFINED') {
          setMessage("Выберите способ доставки");

          return false;
        }

        if (cart.delivery === 'AROUNDTOWN'
          || cart.delivery === 'AROUNDCOUNTRY') {
          if (cart.deliveryAddress === "") {
            setMessage("Введите адрес доставки");

            return false;
          }
        }
      }

      if (cart?.user.type === 'WHOLESALE') {
        if (cart?.delivery === 'UNDEFINED' && cart.deliveryAddress === "") {
          setMessage("Выберите реквизиты");

          return false;
        }
      }
    } else {
      if (cart.delivery === 'UNDEFINED') {
        setMessage("Выберите способ доставки");

        return false;
      }

      if (cart.delivery === 'AROUNDTOWN'
        || cart.delivery === 'AROUNDCOUNTRY') {
        if (cart.deliveryAddress === "") {
          setMessage("Введите адрес доставки");

          return false;
        }
      }
    }

    if (cart?.user !== null) {
      if (!cart?.user?.payNull) {
        if (cart?.pay === 'UNDEFINED') {
          setMessage("Выберите способ оплаты");

          return false;
        }
      } else if (cart.user?.payNull) {
        cart.pay = 'PAYMENTPICKUP';
      }
    }

    if (cart?.pay === 'UNDEFINED') {
      if (!cart.user.payNull) {
        setMessage("Выберите способ оплаты");

        return false;
      } else if (cart.user.payNull) {
        cart.pay = 'PAYMENTPICKUP';
      }
    }

    if (cart?.items.length === 0) {
      setMessage("Корзина пуста");

      return false;
    }

    return true;
  }

  const handleAgreeClose = () => {
    setOpen(false);

    if (checkData()) {
      if (cart?.pay === 'CREDITCARD') {
        HalykBank(cart, router);
      }
      if (cart?.pay === 'PAYMENTPICKUP') {
        const handleCheckout = async () => {
          const result = await Document({ cart });
          if (result) {
            router.navigate('/checkout');
          }
        };

        handleCheckout();
      }
    } else {
      setOpenMessage(true);
    }
  };

  const setItemCart = async () => {
    if (count <= 0) {
      return;
    }

    if ((count === -1) || (uuid === '')) {
      return;
    }

    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const token = (new Token()).get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch('/api/cart/set', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ uuid: uuid, count: count }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setCount(-1);
      setUUID("");

      EventBus.emit('cartUpdated', true);

      loadCart();
    } catch (error) { }

    setOpenCountDialog(false);
  };

  const handleChangeCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setCount(value);
    }
  };

  const handleSetCount = (value: number) => {
    setCount(value);
  };

  const loadCart = async () => {
    setIsChecked(false);

    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const token = (new Token()).get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch('/api/cart', {
        method: 'POST',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const cartData = await response.json();

      setCart(cartData);

      if (cart?.user !== null) {
        setIsChecked(true);
      }
      
    } catch (error) { }
  };

  const loadPayment = async () => {
    try {
      const response = await fetch(`/api/payment/settings`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      const result = await response.json();
      if (result) {
        setPayment(result);
      }
    } catch (error) {
    }
  }

  const removeFromCart = async () => {
    if ((count === -1) || (uuid === '')) {
      return;
    }

    try {
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const token = (new Token()).get();
      if (token) {
        headers.append('Token', token);
      }

      const response = await fetch(`/api/cart/remove/${uuid}`, {
        method: 'POST',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      EventBus.emit('cartUpdated', true);

      loadCart();

    } catch (error) { }
  };

  function getType(type: string) {
    if (type === "LEGAL") {
      return "Юр. лицо";
    }
    return "Физ. лицо";
  }

  useEffect(() => {
    loadCart();
    loadPayment();
  }, []);

  function renderData() {
    return (<>
      <Grid size={12} style={{ textAlign: 'left' }}>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Личные данные<sup><span style={{ paddingLeft: 5, color: "red" }}>*</span></sup>
        </Typography>
      </Grid>

      <Grid container size={12} spacing={1}>
        <Grid size={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <Input
              placeholder="Ф. И. О."
              name="fullName"
              value={cart?.fullName}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid size={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <Input
              name="tel"
              placeholder="Телефон"
              value={cart?.tel}
              onChange={handleChange}
              inputComponent={TextMaskCustom as any}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>);
  }

  function renderDelivery() {
    return (<>
      <Grid size={12} style={{ textAlign: 'left' }}>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Доставка<sup><span style={{ paddingLeft: 5, color: "red" }}>*</span></sup>&nbsp;<sup>(при заказе свыше 50 000 тг доставка осуществляется бесплатно по г. Алматы)</sup>
        </Typography>
      </Grid>

      <FormControl>
        <RadioGroup defaultValue="PICKUP" value={cart?.delivery} onChange={handleDeliveryChange}>
          <FormControlLabel value="PICKUP" control={<Radio />} label="Самовывоз" />
          { (cart?.items.reduce((total, row) => total + row.count * row.price, 0) ?? 0) >= 50000 &&
            <FormControlLabel value="AROUNDTOWN" control={<Radio />} label={<>
              По городу Алматы<sup><span style={{ paddingLeft: 5 }}>
                <Typography component="span" variant="body1" fontWeight="bold">
                  (Яндекс доставка)
                </Typography></span></sup>
            </>} />
          }
          <FormControlLabel value="AROUNDCOUNTRY" control={<Radio />}
            label={<>
              По городам Казахстана через транспортную компанию<sub><span style={{ paddingLeft: 5 }}>
                <Typography component="span" variant="body1" fontWeight="bold">
                  (оплачивает покупатель)
                </Typography></span></sub>
            </>} />
        </RadioGroup>
      </FormControl>
      {(cart?.delivery === "AROUNDTOWN" || cart?.delivery === "AROUNDCOUNTRY") && (
        <FormControl variant="standard" sx={{ width: '100%', marginTop: 2 }}>
          <InputLabel htmlFor="component-delivery-address"><span style={{ paddingLeft: 5 }}>Адрес доставки</span><span style={{ paddingLeft: 5, color: "red" }}>*</span></InputLabel>
          <OutlinedInput id="component-delivery-address" value={cart?.deliveryAddress} onChange={handleChangeDelivery} />
        </FormControl>
      )}
    </>);
  }

  function renderDeliveryRequisite() {
    return (<>
      <Grid size={12} style={{ textAlign: 'left' }}>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Реквизиты<sup><span style={{ paddingLeft: 5, color: "red" }}>*</span></sup>
        </Typography>
      </Grid>
      <FormControl>
        <RadioGroup onChange={(e) => { handleDeliveryRequisiteChange(e) }}>
          {cart?.user.requisites.map((requisite) => (
            <FormControlLabel value={requisite.uuid} control={<Radio />} label={getType(requisite.type || "") + ": " + requisite.company + " [БИН/ИИН: " + requisite.bin + "]"} />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl variant="standard" sx={{ width: '100%', marginTop: 2 }}>
        <InputLabel htmlFor="component-delivery-address"><span style={{ paddingLeft: 5 }}>Адрес доставки</span><span style={{ paddingLeft: 5, color: "red" }}>*</span></InputLabel>
        <OutlinedInput id="component-delivery-address" value={cart?.deliveryAddress} onChange={handleChangeDelivery} />
      </FormControl>

    </>);
  }

  function renderPayment() {
    return (<>
      <Grid size={12} style={{ textAlign: 'left' }} >
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Оплата<sup><span style={{ paddingLeft: 5, color: "red" }}>*</span></sup>
        </Typography>
      </Grid>

      <FormControl>
        <RadioGroup defaultValue="" value={cart?.pay} onChange={handlePayChange}>
          { payment && payment.halyk && 
            <FormControlLabel value="CREDITCARD" control={<Radio />} label="Кредитной картой" />
          }
          {
            /*
            { payment && payment.kaspi && 
              <FormControlLabel value="KASPI" control={<Radio />} label="Kaspi" />
            }
            */
          }
          <FormControlLabel value="PAYMENTPICKUP" control={<Radio />} label="Оплата при самовывозе" />
        </RadioGroup>
      </FormControl>
    </>);
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell width={'40%'}>Наименование</StyledTableCell>
              <StyledTableCell width={'5%'} align="right">Кол.</StyledTableCell>
              <StyledTableCell width={'30%'} style={{ padding: 1, borderRight: 'none' }} align="right">Цена / Сумма</StyledTableCell>
              <StyledTableCell width={'15%'} style={{ borderLeft: 'none' }} align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cart?.items.map((row) => (
              <StyledTableRow key={row.product}>
                <StyledTableCell component="th" scope="row" onClick={() => router.navigate('/product?uuid=' + row.product)}>{row.name}</StyledTableCell>
                <StyledTableCell align="right" onClick={() => { handleClickOpenCountDialog(); handleSetCount(row.count); handleUUID(row.product); }}><Format value={row.count} /></StyledTableCell>
                <StyledTableCell align="right"><Format value={row.price} /><span style={{ paddingLeft: 1 }}>₸</span> / <Format value={row.count * row.price} /><span style={{ paddingLeft: 1 }}>₸</span></StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton aria-label="delete" onClick={() => { handleClickOpenDialog(); handleUUID(row.product); }}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ height: 15 }} />

      <Grid size={12} style={{ textAlign: 'right' }}>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Итого: <Format value={cart?.items.reduce((total, row) => total + row.count * row.price, 0) ?? 0} /><span style={{ paddingLeft: 5 }}>₸</span></Typography>
      </Grid>

      <Box sx={{ height: 15 }} />

      {renderData()}

      <Box sx={{ height: 15 }} />

      {(cart?.user === null || cart?.user.type === "RETAIL") && renderDelivery()}

      {(cart?.user !== null && cart?.user.type === "WHOLESALE") && renderDeliveryRequisite()}

      <Box sx={{ height: 15 }} />

      {renderPayment()}

      {cart?.items && cart.items.length > 0 &&
        <>
          <Box sx={{ height: 15 }} />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
              label={
                <>
                  <Typography component="span" variant="body1">Мною прочитаны</Typography>
                  <span style={{ paddingLeft: 5 }}>
                    <Typography component="span" variant="body1" fontWeight="900" onClick={handleClickOpenConditions('paper')}>условия соглашения</Typography>
                  </span>
                </>}
            />
          </FormGroup>

          <Box sx={{ height: 15 }} />

          <Grid container size={12} spacing={1}>
            <Grid size={12}>
              <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                <Button variant="contained" sx={{ width: '100%', fontSize: '1rem', fontWeight: 'bold' }} disabled={!isChecked} onClick={handleClickOpen}>
                  Оформить
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </>
      }

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Оформление заказа"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Вы действительно хотите оформить заказ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Нет</Button>
          <Button onClick={handleAgreeClose} autoFocus>Да</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogConditions} onClose={handleCloseConditions} scroll={scroll}>
        <DialogContent>
          <DialogContentText>
            <span dangerouslySetInnerHTML={{ __html: conditions }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConditions} autoFocus>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {"Удаление"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Удалить выбранную позицию из корзины?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Нет</Button>
          <Button onClick={() => { handleCloseDialog(); removeFromCart(); }} autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCountDialog} onClose={handleCloseCountDialog}>
        <DialogContent>
          <DialogContentText>
            <TextField
              label="Количество"
              value={count}
              onChange={handleChangeCount}
              name="numberformat"
              slotProps={{
                input: {
                  inputComponent: NumericFormatCustom as any,
                },
              }}
              variant="standard"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCountDialog}>Отмена</Button>
          <Button onClick={() => { handleCloseCountDialog(); setItemCart(); }} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMessage} onClose={handleCloseMessage} >
        <DialogContent>
          <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>OK</Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default CartPage;
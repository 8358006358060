import { useEffect } from 'react';

import User from './User';
import Token from './Token';
import Visit from './Visit';
import Discount from './Discount';
import Dashboard from './Dashboard';

function App() {
  new Token();

  (new Visit()).check();

  useEffect(() => {
    const token = (new URLSearchParams(window.location.search)).get('token');
    
    const loginUser = async () => {
      if (token && token.trim() !== "") {
        const response = await fetch('/api/login/' + token, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        });

        if (response.ok) {
          const login = await response.json();

          (new User()).update(login.fullName);
          (new User()).updateType(login.type);

          (new Token()).update(login.token);

          window.location.href = '/';
        }
      }
    };

    loginUser();
  }, []);
  
  (new Discount());

  return (
    <Dashboard url={window.location.pathname === "/search"? window.location.pathname + window.location.search : '/' }/>
  );
}

export default App;

import * as React from 'react';

import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';

export default function CurrentRouter(initialPath: string): ToolpadRouter {
  const [pathname, setPathname] = React.useState(() => {
    const match = initialPath.match(/(\/[a-zA-Z0-9\-_/]+)/);
    return match ? match[1] : "/";
  });
  const [searchParams, setSearchParams] = React.useState(() => {
    const queryString = initialPath.includes("?") ? initialPath.split("?")[1] : "";
    return new URLSearchParams(queryString);
});

const router = React.useMemo(() => ({
    pathname,
    searchParams,
    navigate: (path: string | URL) => {
      const url = new URL(String(path), window.location.origin);
      setPathname(url.pathname);
      setSearchParams(url.searchParams);
    },
  }), [pathname, searchParams]);

  return router;
}
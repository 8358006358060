import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Router as ToolpadRouter } from "@toolpad/core/AppProvider";

interface PartsCatalogsProps {
  router: ToolpadRouter;
}

export default function PartsCatalogs({ router }: PartsCatalogsProps) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://gui.parts-catalogs.com/v2/parts-catalogs.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Grid container size={12} spacing={{ xs: 2, md: 3 }}>
      <div
        id="parts-catalog"
        data-key="TWS-D117AD4A-6611-4CD7-9742-CFC8C1EEF593"
        data-back-url="https://mobile.fe.kz/search?v={article}"
        data-language="ru"
      ></div>
    </Grid>
  );
}
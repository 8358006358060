import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, IconButton, Autocomplete } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ProductCardPage from './ProductCardPage';
import CircularProgress from '@mui/material/CircularProgress';
import Token from './../Token';
import { Router as ToolpadRouter } from '@toolpad/core/AppProvider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

interface SearchComponentProps {
  router: ToolpadRouter;
  v?: string;
}

interface Product {
  id: number;
  uuid: string;
  name: string;
  price: number;
  img: string;
  rating: number;
  original: string;
  count: number;
  oldPrice: number;
  cars?: string[];
  crosscodes?: string[];
}

interface Search {
  year: number;
  value: string;
  model: string;
  manufacture: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const API_LIST = '/api/search?token=';

async function fetchApi<T>(url: string, search: Search): Promise<T | null> {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(search),
    });

    if (!response.ok) {
      console.error(`Error: ${response.statusText}`);
      return null;
    }

    return await response.json();
  } catch (error) {
    console.error('Network error:', error);
    return null;
  }
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SearchPage: React.FC<SearchComponentProps> = ({ router, v }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [products, setProducts] = useState<Product[]>([]);
  const [, setHasMore] = useState(true);
  const [, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [value,] = React.useState(0);
  const [hasValues,setHasValues] = React.useState(false);

  const handleSearchChange = (_: React.SyntheticEvent, value: string) => {
    setSearchTerm(value);
  };

  const handleSearchClick = async (val?: string) => {
    setProducts([]);
    if (loading) { return; }
    setLoading(true);

    const searchJSON = {
      year: 0,
      value: val === undefined ? searchTerm : val,
      model: '',
      manufacture: '',
    };

    const url = `${API_LIST}${new Token().get()}`;

    try {
      const data = await fetchApi<Product[]>(url, searchJSON);
      if (!data || data.length === 0) {
        setHasMore(false);
        setHasValues(false);
      } else {
        setProducts((prev) => [...prev, ...data]);
        setCurrent((prev) => prev + data.length);
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 1) {
      router.navigate('/parts_catalogs');
    }
  };

  useEffect(() => {
    setProducts([]);
    setCurrent(0);
    setHasMore(true);
    setHasValues(true);

    if (v && v !== "") {
      handleSearchClick(v);
    }
  }, [searchTerm, v]);

  return (
    <>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Поиск" {...a11yProps(0)} />
          <Tab label="По модели / VIN коду" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ padding: 2, backgroundColor: '#FFF', borderRadius: 2, boxShadow: 2, }}>
          <Grid size={10}>
            <Autocomplete
              freeSolo
              options={[]}
              onInputChange={handleSearchChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Номер запчасти или наименование"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 3,
                      paddingRight: '40px',
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid size={2} sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              color="primary"
              onClick={() => handleSearchClick(undefined)}
              sx={{
                padding: 1,
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1,
                boxShadow: 2,
                '&:hover': {
                  backgroundColor: '#FFF',
                },
              }}
            >
              <SearchIcon />
            </IconButton>
          </Grid>

          <Box style={{ height: 25 }}></Box>

          <Grid size={12} container spacing={{ xs: 2, md: 3 }} >

            {products.length === 0 && loading && (
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress />
              </Box>
            )}

            <div style={{ position: 'fixed', height: '100vh', overflow: 'auto', paddingBottom: 280 }} ref={containerRef}>
              <Grid size={12} container spacing={{ xs: 2, md: 3 }}>
                {products.map((product) => (
                  <Grid key={product.uuid} size={6}>
                    <ProductCardPage router={router} product={product} />
                  </Grid>
                ))}
              </Grid>
            </div>
            {(products.length == 0 && !hasValues) &&
              <Grid container alignItems="center" justifyContent="space-between" sx={{ padding: 2, backgroundColor: '#FFF' }}>
                <Grid size={12}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
                    РЕЗУЛЬТАТЫ ПОИСКА
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
                    по вашему запросу ничего не найдено
                  </Typography>
                  <Typography sx={{ textAlign: 'center' }}>
                    Чтобы найти деталь, введите в поиск ее номер или VIN. VIN можно найти в ПТС, а номер детали — в оригинальных каталогах или узнать у эксперта.
                  </Typography>
                </Grid>
              </Grid>
            }

          </Grid>
        </Grid>
      </CustomTabPanel>

    </>
  );
};

export default SearchPage;

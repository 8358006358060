import { PageContainer } from '@toolpad/core/PageContainer';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { AppProvider } from '@toolpad/core/AppProvider';

import THEME from './Theme';
import BRANDING from './Branding';
import NAVIGATION from './Navigation';

import CurrentRouter from './CurrentRouter';
import renderContent from './renderContent';

interface DashboardProps {
  url: string;
}

export default function Dashboard({ url }: DashboardProps) {
  const router = CurrentRouter(url);

  return (
    <AppProvider
      navigation={NAVIGATION()}
      router={router}
      theme={THEME}
      branding={BRANDING}
    >
      <DashboardLayout>
        <PageContainer title={""} >
          {renderContent(router)}
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );
}
